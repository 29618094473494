import {
    AUTH_ATTEMPTS_KEY,
    REQUEST_ATTEMPTS_KEY,
    TOKEN_KEY,
    USER_PHONE_NUMBER_KEY,
} from '@ocenkatech/common/const';
import { isErrorDataMessage, saveInStorage } from '@ocenkatech/common/lib';
import { tokenActions } from '@ocenkatech/common/models';
import { FC, useCallback, memo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'shared/lib/react/hooks';
import { removeFromStorage } from 'shared/lib/removeFromStorage';
import { Button } from 'shared/ui/Button';
import { Form } from 'shared/ui/Form';
import { Input } from 'shared/ui/Input';
import { usePopup } from 'shared/ui/Popup';
import { useSmsCheckMutation } from '../../api/auth';
import { getAuthUser } from '../../model/selectors/getAuthUser';
import { getPhoneNumber } from '../../model/selectors/getPhoneNumber';
import { authActions } from '../../model/slice/authSlice';

export type ConfirmCodeProps = {
    code: string;
};

interface ConfirmCodeFormProps {}

export const ConfirmCodeForm: FC<ConfirmCodeFormProps> = memo(() => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<ConfirmCodeProps>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [check, { isLoading }] = useSmsCheckMutation();
    const authUser = useAppSelector(getAuthUser);
    const phoneNumber = useAppSelector(getPhoneNumber);
    const { openPopup } = usePopup({
        title: 'Произошла ошибка при подтверждении кода',
    });

    const onConfirmCode: SubmitHandler<ConfirmCodeProps> = useCallback(
        async (data) => {
            try {
                if (!authUser.authAttempts) {
                    openPopup({
                        title: 'Вы исчерпали все попытки авторизации, попробуйте позже',
                    });
                    return;
                }
                const result = await check({
                    phoneNumber: phoneNumber,
                    code: data.code,
                }).unwrap();

                saveInStorage(TOKEN_KEY, result.token, true);
                dispatch(tokenActions.setToken(result.token));
                removeFromStorage([
                    USER_PHONE_NUMBER_KEY,
                    REQUEST_ATTEMPTS_KEY,
                    AUTH_ATTEMPTS_KEY,
                ]);
                navigate(location.state?.from?.pathname || '/', {
                    replace: true,
                });
            } catch (error) {
                if (isErrorDataMessage(error)) {
                    dispatch(authActions.decrementAuthAttempts());
                    openPopup({
                        content: Object.values(error.data).join(', '),
                    });
                }
            }
        },
        [
            authUser.authAttempts,
            check,
            dispatch,
            location.state?.from?.pathname,
            navigate,
            openPopup,
            phoneNumber,
        ],
    );

    return (
        <Form onSubmit={handleSubmit(onConfirmCode)}>
            <Input
                formKey="code"
                label="Код подтверждения"
                mask="9999"
                inputProps={{
                    placeholder: 'Введите код подтверждения из смс',
                    inputMode: 'decimal',
                }}
                registerOptions={{
                    required: true,
                    pattern: {
                        value: /^[0-9]{4}$/,
                        message: 'Код должен состоять из 4 цифр',
                    },
                }}
                rightText={`осталось попыток ${authUser.authAttempts}`}
                error={errors.code}
                register={register}
            />
            <Button
                type="submit"
                size="lg"
                className="mt-6 w-full uppercase"
                isLoading={isLoading}>
                Войти
            </Button>
        </Form>
    );
});
