import { useLazyApiV1PortalAddressAutocompleteRetrieveQuery } from '@ocenkatech/common/api';
import { useDebounce } from '@ocenkatech/common/lib';
import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import {
    Controller,
    FieldError,
    FieldValues,
    Path,
    UseControllerProps,
} from 'react-hook-form';
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg';
import { Autocomplete } from 'shared/ui/Select';

interface AddressAutoCompleteProps<T extends FieldValues> {
    name: Path<T>;
    label: string;
    control: UseControllerProps<T>['control'];
    error?: FieldError;
    rules?: UseControllerProps<T>['rules'];
    value?: string;
    className?: string;
    isLoading?: boolean;
    emptyValue?: string;
}

export const AddressAutoComplete = <T extends FieldValues>({
    name,
    label,
    control,
    error,
    rules,
    value = '',
    className = '',
    isLoading,
    emptyValue,
}: AddressAutoCompleteProps<T>) => {
    const [query, setQuery] = useState(value);
    const debouncedQuery = useDebounce(query, 250);
    const [fetchAddress, { currentData: addresses, isFetching }] =
        useLazyApiV1PortalAddressAutocompleteRetrieveQuery();

    const onSearchAddress = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setQuery(event.target.value.toLocaleLowerCase());
        },
        [],
    );

    useEffect(() => {
        if (debouncedQuery) {
            fetchAddress({ query: debouncedQuery, limit: 10 });
        }
    }, [debouncedQuery, fetchAddress]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    formKey={field.name}
                    label={label}
                    options={
                        (addresses as string[])?.map((address) => ({
                            value: address,
                            name: address,
                        })) || []
                    }
                    emptyValue={emptyValue}
                    onChangeQuery={onSearchAddress}
                    fieldClassName={className}
                    icon={<SearchIcon className="size-4" />}
                    isLoading={isLoading}
                    isProgress={isFetching}
                    isAddOption
                    required={!!rules?.required}
                    error={error}
                />
            )}
        />
    );
};
