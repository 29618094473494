import {
    CustomerOrderRead,
    useLazyApiV1CrmCustomerOrdersRetrieveQuery,
} from '@ocenkatech/common/api';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useEffect,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { useParams } from 'react-router';

type CustomerOrderContextType = {
    order?: CustomerOrderRead;
    error?: FetchBaseQueryError | SerializedError;
    isFetching: boolean;
    refetchOrder: () => Promise<void>;
    setOrder: Dispatch<SetStateAction<CustomerOrderRead | undefined>>;
};
const CustomerOrderContext = createContext<CustomerOrderContextType>({
    isFetching: false,
    refetchOrder: function (): Promise<void> {
        throw new Error('Function not implemented.');
    },
    setOrder: function (
        _: SetStateAction<CustomerOrderRead | undefined>,
    ): void {
        throw new Error('Function not implemented.');
    },
});

export const useCustomerOrderContext = () => {
    const props = useContext(CustomerOrderContext);
    if (!props) {
        throw new Error(
            'useOrderContext must be used within a OrderProvider component',
        );
    }

    return props;
};

export const CustomerOrderProvider: FC<PropsWithChildren> = ({ children }) => {
    const { id } = useParams();
    const [order, setOrder] = useState<CustomerOrderRead>();
    const [fetchOrder, { error, isFetching }] =
        useLazyApiV1CrmCustomerOrdersRetrieveQuery();

    const refetchOrder = useCallback(async () => {
        try {
            if (id && !!Number(id)) {
                setOrder(await fetchOrder({ id }).unwrap());
            }
        } catch (error) {}
    }, [fetchOrder, id]);

    useEffect(() => {
        refetchOrder();
    }, [refetchOrder]);

    return (
        <CustomerOrderContext.Provider
            value={{ order: order, error, isFetching, refetchOrder, setOrder }}>
            {children}
        </CustomerOrderContext.Provider>
    );
};
