import { Listbox, Transition } from '@headlessui/react';
import { classnames } from '@ocenkatech/common/lib';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { ReactComponent as Caret } from '../../../../assets/icons/caret.svg';
import { Field, FieldProps } from '../../../Field';
import { Option } from '../../module/types';
import cls from '../../styles/Select.module.css';

type SelectProps<T extends FieldValues> = FieldProps<T> & {
    options: Option[];
    value?: string | number | null;
    emptyValue?: string;
    onChange?: (value: string) => void;
};

export const Select = React.forwardRef(
    <T extends FieldValues>(
        props: SelectProps<T>,
        ref: React.Ref<HTMLElement>,
    ) => {
        const {
            formKey,
            label,
            hint,
            error,
            required,
            size,
            fieldClassName,
            onChange,
            value,
            emptyValue,
            isLoading,
            options,
        } = props;

        return (
            <Field
                formKey={formKey}
                label={label}
                hint={hint}
                error={error}
                required={required}
                size={size}
                fieldClassName={fieldClassName}
                isLoading={isLoading}>
                <Listbox value={value} onChange={onChange} ref={ref}>
                    {({ open }) => (
                        <div className="relative">
                            <Listbox.Button
                                className={classnames(cls.select, {
                                    '!ring-error': !!error,
                                })}>
                                <span className="grow text-left font-medium">
                                    {value ? (
                                        options.find(
                                            (option) =>
                                                String(value) ===
                                                String(option.value),
                                        )?.name
                                    ) : (
                                        <span className="text-text-secondaryLight">
                                            {emptyValue}
                                        </span>
                                    )}
                                </span>
                                <span className={cls.caret}>
                                    <Caret
                                        className={classnames(
                                            'text-primary-base size-2 transition-transform',
                                            { 'rotate-180': open },
                                        )}
                                    />
                                </span>
                            </Listbox.Button>
                            {open && (
                                <Transition
                                    as={React.Fragment}
                                    leave="transition ease-in duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <Listbox.Options
                                        static
                                        className={`${cls.optionsContainer} custom-scroll list-none`}>
                                        {options.map((option) => (
                                            <Listbox.Option
                                                key={option.value}
                                                value={option.value}>
                                                {({ selected }) => (
                                                    <span
                                                        className={classnames(
                                                            cls.option,
                                                            {
                                                                'bg-primary-20':
                                                                    selected,
                                                            },
                                                        )}>
                                                        {option.name}
                                                    </span>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            )}
                        </div>
                    )}
                </Listbox>
            </Field>
        );
    },
);
