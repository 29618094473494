import { FC } from 'react';

interface LoaderProps {
    className?: string;
}

export const Loader: FC<LoaderProps> = ({ className = '' }) => {
    return (
        <div
            className={`inline-block size-3 animate-spin rounded-full border-2 border-inherit !border-y-transparent ${className}`}></div>
    );
};
