'use client';
var _a$5;
var ErrorType;
(function (ErrorType) {
    ErrorType["REQUIRED"] = "required";
    ErrorType["EMAIL"] = "email";
    ErrorType["BIRTHDAY"] = "birthday";
    ErrorType["DATEMAXNOW"] = "dateMaxNow";
    ErrorType["DATEMINNOW"] = "dateMinNow";
    ErrorType["COUNTSYMBOL"] = "countSymbol";
    ErrorType["MIN"] = "min";
})(ErrorType || (ErrorType = {}));
var ErrorMessageLabel = (_a$5 = {},
    _a$5[ErrorType.REQUIRED] = 'Поле обязательное для заполнения',
    _a$5[ErrorType.EMAIL] = 'Введите корректное значение E-mail',
    _a$5[ErrorType.BIRTHDAY] = 'Дата имеет неверный формат. Вместо этого используйте один из этих форматов: ГГГГ-ММ-ДД.',
    _a$5[ErrorType.DATEMAXNOW] = 'Дата не может быть больше чем {0}',
    _a$5[ErrorType.DATEMINNOW] = 'Дата не может быть меньше чем {0}',
    _a$5[ErrorType.COUNTSYMBOL] = 'Количество символов должно составлять: {0} символов',
    _a$5[ErrorType.MIN] = 'Минимальное значение {0}',
    _a$5);

var ErrorStatusLabels = {
    FETCH_ERROR: 'Ошибка, возникшая во время выполнения выборки или опции обратного вызова fetchFn.',
    PARSING_ERROR: 'Во время разбора JSON файла произошла ошибка',
    TIMEOUT_ERROR: 'Ошибка тайм-аута',
    CUSTOM_ERROR: '',
    404: 'Страница не найдена',
};

var SummerRooms;
(function (SummerRooms) {
    SummerRooms[SummerRooms["UNKNOWN"] = -1] = "UNKNOWN";
    SummerRooms[SummerRooms["NO"] = 0] = "NO";
    SummerRooms[SummerRooms["BALCONY"] = 1] = "BALCONY";
    SummerRooms[SummerRooms["LOGGIA"] = 2] = "LOGGIA";
})(SummerRooms || (SummerRooms = {}));
var RenovationTypes;
(function (RenovationTypes) {
    RenovationTypes[RenovationTypes["UNKNOWN"] = -1] = "UNKNOWN";
    RenovationTypes[RenovationTypes["NO"] = 0] = "NO";
    RenovationTypes[RenovationTypes["COSMETIC"] = 1] = "COSMETIC";
    RenovationTypes[RenovationTypes["EURO"] = 2] = "EURO";
    RenovationTypes[RenovationTypes["DESIGN"] = 3] = "DESIGN";
    RenovationTypes[RenovationTypes["PRECLEAN"] = 4] = "PRECLEAN";
    RenovationTypes[RenovationTypes["ROUGH"] = 5] = "ROUGH";
})(RenovationTypes || (RenovationTypes = {}));
var HouseType;
(function (HouseType) {
    HouseType[HouseType["UNKNOWN"] = -1] = "UNKNOWN";
    HouseType[HouseType["NEW"] = 1] = "NEW";
    HouseType[HouseType["RESALE"] = 2] = "RESALE";
})(HouseType || (HouseType = {}));
var WindowsView;
(function (WindowsView) {
    WindowsView[WindowsView["UNKNOWN"] = -1] = "UNKNOWN";
    WindowsView[WindowsView["YARD"] = 0] = "YARD";
    WindowsView[WindowsView["STREET"] = 1] = "STREET";
})(WindowsView || (WindowsView = {}));
var WallTypes;
(function (WallTypes) {
    WallTypes["UNKNOWN"] = "unknown";
    WallTypes["BRICK"] = "brick";
    WallTypes["PANEL"] = "panel";
    WallTypes["BLOCK"] = "block";
    WallTypes["MONOLITH"] = "monolith";
    WallTypes["MONOLITH_BRICK"] = "monolith_brick";
    WallTypes["WOODEN"] = "wooden";
})(WallTypes || (WallTypes = {}));

var _a$4;
var HouseTypeLabels = (_a$4 = {},
    _a$4[HouseType.UNKNOWN] = 'Неизвестно',
    _a$4[HouseType.NEW] = 'Новостройка',
    _a$4[HouseType.RESALE] = 'Вторичка',
    _a$4);

var LayoutTypeLabels = {
    free: 'Фиксированная',
    fixed: 'Свободная',
};

var OrderStatusLabels = {
    lead: 'Лид',
    customer: 'Клиeнт',
    draft: 'Черновик',
    inspection_requested: 'Запрос на осмотр',
    inspection_waiting: 'Ожидание осмотра',
    appraiser_queued: 'В очереди',
    appraiser_selected: 'Оценщик выбран',
    inspection_confirmed: 'Осмотр подтвержден',
    appraiser_report_uploaded: 'Отчет оценщика загружен',
    appraiser_report_signed: 'Отчет оценщика подписан',
    appraiser_declined: 'Оценщик отказался',
    completed: 'Завершен',
    archived: 'В архиве',
};

var _a$3;
var Pattern;
(function (Pattern) {
    Pattern["EMAIL"] = "email";
})(Pattern || (Pattern = {}));
var PatternValidation = (_a$3 = {},
    _a$3[Pattern.EMAIL] = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    _a$3);

var PurposeLabels = {
    pay_mortgage: 'Оплачиваю ипотеку',
    get_mortgage: 'Получаю ипотеку',
    other: 'Другое',
};

var _a$2;
var RenovationsLabels = (_a$2 = {},
    _a$2[RenovationTypes.UNKNOWN] = 'Неизвестно',
    _a$2[RenovationTypes.NO] = 'Без ремонта',
    _a$2[RenovationTypes.COSMETIC] = 'Под чистовую',
    _a$2[RenovationTypes.EURO] = 'Евроремонт',
    _a$2[RenovationTypes.DESIGN] = 'Дизайнерский',
    _a$2[RenovationTypes.PRECLEAN] = 'Предчистовой',
    _a$2[RenovationTypes.ROUGH] = 'Черновой',
    _a$2);
var RenovationTypesLabels = {
    unknown: 'Неизвестно',
    none: 'Без ремонта',
    preclean: 'Под чистовую',
    average: 'Среднее жилое состояние / требуется косметический ремонт',
    good: 'Хорошее состояние',
    euro: 'Отличное (Евроремонт)',
    premium: 'Ремонт премиум класса',
};

var SummerRoomsLabels = {
    0: 'Лоджия',
    1: 'Балкон',
    '-1': 'Нет',
    2: 'Лоджия и балкон',
};

var SummerRoomsTypeLabels = {
    none: 'Нет',
    loggia: 'Лоджия',
    balcony: 'Балкон',
    two_balcony: 'Два балкона',
    two_loggia: 'Две лоджии',
    loggia_and_balcony: 'Лоджия и балкон',
};

var _a$1;
var WallTypesLabels = (_a$1 = {},
    _a$1[WallTypes.UNKNOWN] = 'Неизвестно',
    _a$1[WallTypes.BRICK] = 'Кирпич',
    _a$1[WallTypes.PANEL] = 'Панель',
    _a$1[WallTypes.BLOCK] = 'Блок',
    _a$1[WallTypes.MONOLITH] = 'Монолит',
    _a$1[WallTypes.MONOLITH_BRICK] = 'Монолит-кирпич',
    _a$1[WallTypes.WOODEN] = 'Дерево',
    _a$1);

var _a;
var WindowsViewLabels = (_a = {},
    _a[WindowsView.UNKNOWN] = 'Неизвестно',
    _a[WindowsView.STREET] = 'На улицу',
    _a[WindowsView.YARD] = 'Во двор',
    _a);

var TOKEN_KEY = 'auth.token';
var KEEP_LOGGED_ID = 'auth.keepLoggedIn';
var USER_PHONE_NUMBER_KEY = 'auth.phoneNumber';
var REQUEST_ATTEMPTS_KEY = 'auth.requestAttempts';
var AUTH_ATTEMPTS_KEY = 'auth.authAttempts';

export { AUTH_ATTEMPTS_KEY, ErrorMessageLabel, ErrorStatusLabels, HouseTypeLabels, KEEP_LOGGED_ID, LayoutTypeLabels, OrderStatusLabels, PatternValidation, PurposeLabels, REQUEST_ATTEMPTS_KEY, RenovationTypesLabels, RenovationsLabels, SummerRoomsLabels, SummerRoomsTypeLabels, TOKEN_KEY, USER_PHONE_NUMBER_KEY, WallTypesLabels, WindowsViewLabels };
