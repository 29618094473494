import { CustomerOrderWrite } from '@ocenkatech/common/dist/api';
import { PropsWithChildren, createContext, useContext, FC } from 'react';
import { Path } from 'react-hook-form';

type UpdateHandlerContextType = {
    onUpdateOrder: (keys?: Path<CustomerOrderWrite>[]) => Promise<void>;
    // onUpdateCustomer: () => Promise<void>;
    // onUpdateInspection: () => Promise<void>;
};

const UpdateHandlerContext = createContext<UpdateHandlerContextType | null>(
    null,
);

export const useUpdateHandler = () => {
    const props = useContext(UpdateHandlerContext);
    if (!props) {
        throw new Error(
            'useUpdateHandler must be used within a UpdateHandlerProvider component',
        );
    }

    return props;
};

export const UpdateHandlerProvider: FC<
    PropsWithChildren & { value: UpdateHandlerContextType }
> = ({ value, children }) => {
    return (
        <UpdateHandlerContext.Provider value={value}>
            {children}
        </UpdateHandlerContext.Provider>
    );
};
