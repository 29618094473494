import { useApiV1PortalCustomerOrdersCreateMutation } from '@ocenkatech/common/api';
import { FC } from 'react';
import { useMultiFormProvider } from 'entities/orders';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { NewApplicationForm } from './NewApplicationForm';

interface NewOrderModalProps {
    open: boolean;
    setOpen: (arg: boolean) => void;
}

export const NewApplicationModal: FC<NewOrderModalProps> = ({
    open,
    setOpen,
}) => {
    const { orderForm } = useMultiFormProvider();
    const [create] = useApiV1PortalCustomerOrdersCreateMutation();

    const onClick = async () => {
        const isValidOrder = await orderForm.trigger();
        if (!isValidOrder) return;
        const isSuccess = await create({
            customerOrderCreate: orderForm.getValues(),
        });
        if (isSuccess) {
            orderForm.reset({
                purpose: '',
                address: '',
                bank: null,
                bankName: '',
                promo_code: '',
                customer: { name: '', phone: '' },
            });
            setOpen(false);
        }
    };

    return (
        <Modal
            size="lg"
            open={open}
            setOpen={setOpen}
            title="Оформление заказа"
            actionSuccess={
                <Button type="button" onClick={onClick}>
                    Отправить заявку
                </Button>
            }>
            <NewApplicationForm />
        </Modal>
    );
};
