import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';
import { Button } from '../../Button';
import { LinkApp } from '../../LinkApp';

export type ItemMenu = { name: string; href: string };

interface DropdownProps {
    menu: ItemMenu[];
    icon?: ReactNode;
    titleButton?: string;
    className?: string;
}

export const Dropdown: FC<DropdownProps> = ({
    icon,
    titleButton,
    menu,
    className = '',
}) => {
    return (
        <Menu as="div" className={`relative ${className}`}>
            <div>
                <Menu.Button
                    as={Button}
                    theme="white"
                    icon
                    square
                    className="border-primary-100 border">
                    {icon} {titleButton}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {menu.map((item) => (
                        <Menu.Item key={item.name}>
                            {() => (
                                <LinkApp
                                    to={item.href}
                                    baseClass="block px-4 py-2 text-sm text-gray-700"
                                    activeClass="bg-gray-100">
                                    {item.name}
                                </LinkApp>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
