import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { store } from '../config/store';

interface StoreProviderProps {}

export const StoreProvider: FC<PropsWithChildren<StoreProviderProps>> = ({
    children,
}) => {
    return <Provider store={store}>{children}</Provider>;
};
