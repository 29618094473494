import React, { FormEventHandler, PropsWithChildren } from 'react';
import { Loader } from '../../Loader';

export type FormProps = {
    inProgress?: boolean;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    className?: string;
    formId?: string;
};

export const Form: React.FC<PropsWithChildren<FormProps>> = ({
    inProgress,
    onSubmit,
    children,
    className = '',
    formId,
}) => {
    return (
        <form
            id={formId}
            onSubmit={onSubmit}
            className={`relative ${className}`}>
            {inProgress && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                    <div className="absolute inset-0 bg-white opacity-40"></div>
                    <Loader className="border-primary-base z-20 !h-16 !w-16 !border-4" />
                </div>
            )}
            {children}
        </form>
    );
};
