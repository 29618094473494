import { classnames } from '@ocenkatech/common/lib';
import { FC, ReactNode } from 'react';
import { Button } from '../../Button';
import { Modal } from '../../Modal';
import { Typography } from '../../Typography';

interface PopupProps {
    open?: boolean;
    setOpen?: (arg: boolean) => void;
    title?: string;
    image?: string;
    children?: ReactNode;
}

export const Popup: FC<PopupProps> = ({
    open,
    setOpen,
    title,
    image,
    children,
}) => {
    return (
        <>
            <Modal
                size={image ? 'lg' : 'xs'}
                open={open}
                setOpen={setOpen}
                isBtnClose={false}>
                <div className={classnames({ flex: !!image })}>
                    {image && (
                        <div className="-z-10 hidden max-h-[274px] w-full scale-[1.35] md:block">
                            <img
                                className="mx-auto h-full w-full object-contain"
                                src={image}
                                alt={title}
                            />
                        </div>
                    )}
                    <div className="flex flex-col md:max-w-[352px]">
                        <Typography type="h3" className="mb-4 leading-none">
                            {title}
                        </Typography>
                        <p className="text-text-secondary grow text-sm leading-4">
                            {children}
                        </p>
                        <Button
                            onClick={() => setOpen?.(false)}
                            className="mt-2 w-full">
                            Принять
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
