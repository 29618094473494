import { generatedApi } from '@ocenkatech/common/api';
import { tokenReducer } from '@ocenkatech/common/models';
import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { authApi, authReducer } from 'features/auth';

export const store = configureStore({
    reducer: {
        [generatedApi.reducerPath]: generatedApi.reducer,
        auth: authReducer,
        token: tokenReducer,
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            generatedApi.middleware,
        ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
