import { FC, memo, forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { setActiveClass } from '../lib/setActiveClass';
import { LinkAppTheme } from '../module/types';
import cls from './LinkApp.module.css';

interface LinkAppProps
    extends NavLinkProps,
        React.RefAttributes<HTMLAnchorElement> {
    baseClass?: string;
    activeClass?: string;
    theme?: LinkAppTheme;
}

const LinkAppInner: FC<LinkAppProps> = forwardRef(
    (
        {
            to,
            baseClass = cls.link,
            activeClass = '',
            className = '',
            children,
            target,
            theme = 'primary',
            ...otherProps
        },
        ref,
    ) => {
        return (
            <NavLink
                to={to}
                ref={ref}
                target={target}
                className={setActiveClass(
                    `${baseClass} ${cls[theme]} ${className}`,
                    activeClass,
                )}
                {...otherProps}>
                {children}
            </NavLink>
        );
    },
);

export const LinkApp = memo(LinkAppInner);
