export function removeFromStorage(key: string | string[]) {
    if (Array.isArray(key)) {
        key.forEach((k) => {
            localStorage.removeItem(k);
            sessionStorage.removeItem(k);
        });
    } else {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }
}
