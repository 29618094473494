import { StepEnum } from '@ocenkatech/common/api';

export const orderSteps: StepEnum[] = [
    'initial_data',
    'filling_data',
    'preassessment',
    'photo',
    'documents',
    'result',
    'final',
];
