import { CustomerOrderWrite } from '@ocenkatech/common/api';
import { createContext, PropsWithChildren, FC, useContext } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

type MultiFormContextType = {
    orderForm: UseFormReturn<CustomerOrderWrite>;
};

const MultiFormContext = createContext<MultiFormContextType | null>(null);

export const useMultiFormProvider = () => {
    const props = useContext(MultiFormContext);

    if (!props) {
        throw new Error(
            'useMultiFormProvider must be used within a MultiFormProvider component',
        );
    }

    return props;
};

export const MultiFormProvider: FC<PropsWithChildren> = ({ children }) => {
    const orderForm = useForm<CustomerOrderWrite>({
        mode: 'onChange',
    });

    return (
        <MultiFormContext.Provider value={{ orderForm }}>
            {children}
        </MultiFormContext.Provider>
    );
};
