import { useCallback, useContext } from 'react';
import { PopupFunctionParams } from '../../model/Popup';
import { PopupContext } from '../PopupProvider';

export type PopupMethods = {
    openPopup: (props?: PopupFunctionParams) => void;
    closePopup: () => void;
};

export function usePopup(props: PopupFunctionParams = {}): PopupMethods {
    const { closePopup, displayPopup } = useContext(PopupContext);
    const openPopup = useCallback(
        (openProps: PopupFunctionParams = {}) => {
            const propsWithDefaults = {
                ...props,
                ...openProps,
            };
            displayPopup(propsWithDefaults);
        },
        [displayPopup, props],
    );

    return {
        openPopup,
        closePopup,
    };
}
