import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PopupProvider } from 'shared/ui/Popup';
import { AppRouterProvider } from './providers/router';

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <PopupProvider>
                <AppRouterProvider />
            </PopupProvider>
        </DndProvider>
    );
}

export default App;
