import { useState, ReactNode, createContext } from 'react';
import { PopupFunctionParams, PopupProps } from '../model/Popup';
import { Popup } from '../ui/Popup';
import { setPopupClosed, setPopupProps, setPopupOpen } from './reducers';

export const PopupContext = createContext<{
    displayPopup: (props?: PopupFunctionParams) => void;
    closePopup: () => void;
}>({
    displayPopup: function (): void {
        throw new Error('Function not implemented.');
    },
    closePopup: function (): void {
        throw new Error('Function not implemented.');
    },
});

export function PopupProvider({
    children,
}: {
    children?: ReactNode;
} = {}) {
    const [popupProp, setPopupProp] = useState<PopupProps>({});

    const displayPopup = (args?: PopupFunctionParams): void => {
        setPopupProp((previous) => setPopupOpen(previous));
        setPopupProp((previous) => setPopupProps(previous, { ...args }));
    };
    const closePopup = (): void => {
        setPopupProp((previous) => setPopupClosed(previous));
    };

    return (
        <PopupContext.Provider
            value={{
                displayPopup,
                closePopup,
            }}>
            {children}

            <Popup
                open={popupProp.open}
                image={popupProp.image}
                setOpen={(arg) => (arg ? displayPopup() : closePopup())}
                title={popupProp.title}>
                {popupProp.children}
            </Popup>
        </PopupContext.Provider>
    );
}
