import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { forOwn } from 'lodash';
import { useEffect } from 'react';
import { FieldError, Path } from 'react-hook-form';

export function useHandleErrors<T extends unknown>(
    error: FetchBaseQueryError | SerializedError | undefined,
    setError: (
        name: keyof T,
        error: FieldError,
        options?: { shouldFocus: boolean },
    ) => void,
    validate?: Path<T>[],
) {
    useEffect(() => {
        function setErrorForm(data: unknown, key?: string) {
            forOwn(data, (v, k) => {
                if (
                    validate === undefined ||
                    validate?.includes(k as Path<T>)
                ) {
                    const keys = key ? [key] : [];
                    if (!Array.isArray(v)) {
                        keys.push(k);
                        return setErrorForm(v, keys.join('.'));
                    }
                    keys.push(k);
                    setError(keys.join('.') as keyof T, {
                        type: 'custom',
                        message: v[0],
                    });
                }
            });
        }
        if (error && 'status' in error && error.status === 400) {
            'data' in error
                ? (error.data as Record<keyof T, string[]>)
                : ({} as Record<keyof T, string[]>);

            setErrorForm(error.data);
        }
    }, [error, setError, validate]);
}
