import { Combobox, Transition } from '@headlessui/react';
import { classnames } from '@ocenkatech/common/lib';
import {
    Fragment,
    ChangeEvent,
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useState,
    ReactNode,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { ReactComponent as Caret } from '../../../../assets/icons/caret.svg';
import { Field, FieldProps } from '../../../Field';
import { Loader } from '../../../Loader';
import { Option } from '../../module/types';
import cls from '../../styles/Select.module.css';

type AutocompleteProps<T extends FieldValues> = FieldProps<T> & {
    options: Option[];
    value?: string | number | null;
    emptyValue?: string;
    onChange: (value: string) => void;
    icon?: ReactNode;
    isProgress?: boolean;
    isAddOption?: boolean;
    onChangeQuery?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const AutocompleteInner = <T extends FieldValues>(
    props: AutocompleteProps<T>,
    ref?: ForwardedRef<HTMLElement>,
) => {
    const {
        formKey,
        label,
        hint,
        error,
        required,
        size,
        icon,
        fieldClassName,
        onChange,
        value = '',
        emptyValue,
        isLoading,
        isProgress,
        isAddOption,
        options,
        onChangeQuery,
    } = props;

    const [query, setQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) =>
                  option.name.toLowerCase().includes(query.toLowerCase()),
              );

    const onSelectOption = useCallback(
        (option: Option) => {
            setSelectedOption(option);
            onChange?.(option.value);
        },
        [onChange],
    );

    useEffect(() => {
        if (value) {
            const option = options.find(
                (option) => String(option.value) === String(value),
            );

            if (option) {
                setSelectedOption(option);
            }
        }
    }, [options, value]);

    return (
        <Field
            formKey={formKey}
            label={label}
            hint={hint}
            error={error}
            required={required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}>
            <Combobox
                as="div"
                ref={ref}
                value={selectedOption}
                onChange={onSelectOption}>
                <div
                    className={classnames(cls.select, {
                        '!ring-error': !!error,
                    })}>
                    <Combobox.Input
                        className="!rounded-none !p-0 !ring-0 focus:!ring-0"
                        displayValue={(option: Option | null) =>
                            option ? option.name : String(value)
                        }
                        onChange={(event) => {
                            setQuery(event.target.value);
                            onChangeQuery?.(event);
                        }}
                        placeholder={emptyValue}
                    />
                    <Combobox.Button
                        className={(bag) =>
                            classnames(cls.caret, {
                                'rotate-180': bag.open && !icon,
                            })
                        }>
                        {isProgress ? (
                            <Loader className="border-primary-base size-4" />
                        ) : icon ? (
                            icon
                        ) : (
                            <Caret
                                className={classnames(
                                    'text-primary-base size-2 transition-transform',
                                )}
                            />
                        )}
                    </Combobox.Button>
                </div>
                {!isProgress && (
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}>
                        <Combobox.Options
                            className={`${cls.optionsContainer} custom-scroll list-none`}>
                            {!filteredOptions.length && query !== '' ? (
                                <>
                                    <span className={cls.option}>
                                        Ничего не найдено.
                                    </span>
                                    {isAddOption && (
                                        <Combobox.Option
                                            key="add"
                                            value={{
                                                name: query,
                                                value: query,
                                            }}
                                            className={cls.option}>
                                            {({ active, selected }) => (
                                                <span
                                                    className={classnames(
                                                        cls.option,
                                                        {
                                                            'bg-primary-20':
                                                                selected ||
                                                                active,
                                                            'text-primary-base':
                                                                active,
                                                        },
                                                    )}>
                                                    Добавить "{query}"
                                                </span>
                                            )}
                                        </Combobox.Option>
                                    )}
                                </>
                            ) : (
                                filteredOptions.map((option) => (
                                    <Combobox.Option
                                        key={option.value}
                                        value={option}>
                                        {({ active, selected }) => (
                                            <span
                                                className={classnames(
                                                    cls.option,
                                                    {
                                                        'bg-primary-20':
                                                            selected || active,
                                                        'text-primary-base':
                                                            active,
                                                    },
                                                )}>
                                                {option.name}
                                            </span>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                )}
            </Combobox>
        </Field>
    );
};

export const Autocomplete = forwardRef(AutocompleteInner);
