import { PopupFunctionParams, PopupProps } from '../model/Popup';
import { enforceExistingPopup } from './utils';

function togglePopupOpenState(popup: PopupProps, value: boolean): PopupProps {
    enforceExistingPopup(popup);
    return {
        ...popup,
        open: value,
    };
}

export function setPopupOpen(popup: PopupProps): PopupProps {
    enforceExistingPopup(popup);
    return togglePopupOpenState(popup, true);
}

export function setPopupClosed(popup: PopupProps): PopupProps {
    enforceExistingPopup(popup);
    return togglePopupOpenState(popup, false);
}

export function setPopupProps(
    popup: PopupProps,
    newProps: PopupFunctionParams,
): PopupProps {
    enforceExistingPopup(popup);
    return {
        ...popup,
        ...newProps,
        children: newProps.content || popup.children,
    };
}
