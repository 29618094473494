import { useApiV1BanksListQuery } from '@ocenkatech/common/api';
import { classnames } from '@ocenkatech/common/lib';
import { useEffect, useState } from 'react';
import {
    FieldError,
    FieldValues,
    Path,
    PathValue,
    UseControllerProps,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';
import { MessageError } from 'shared/ui/Field';
import { Input } from 'shared/ui/Input';
import { BankControl } from '../BankControl/BankControl';
import { Skeleton } from './Skeleton';

type BanksIconListProps<T extends FieldValues> = {
    name: Path<T>;
    control?: UseControllerProps<T>['control'];
    rules?: UseControllerProps<T>['rules'];
    error?: FieldError;
    value?: string | number | null;
    isOtherBank?: false;
    register?: UseFormRegister<T>;
    nameOtherBank?: Path<T>;
    errorOtherBank?: FieldError;
    valueOtherBank?: PathValue<T, Path<T>>;
    setValue?: UseFormSetValue<T>;
};

type BankOther<T extends FieldValues> = Omit<
    BanksIconListProps<T>,
    'isOtherBank'
> & {
    isOtherBank?: true;
    register: UseFormRegister<T>;
    nameOtherBank: Path<T>;
    valueOtherBank: PathValue<T, Path<T>>;
    setValue: UseFormSetValue<T>;
};

export const BanksIconList = <T extends FieldValues>({
    name,
    control,
    rules,
    error,
    value,
    isOtherBank,
    register,
    nameOtherBank,
    valueOtherBank,
    setValue,
    errorOtherBank,
}: BanksIconListProps<T> | BankOther<T>) => {
    const [isShowOther, setIsShowOther] = useState(value === -1 || !value);
    const { currentData: banks, isLoading } = useApiV1BanksListQuery();
    useEffect(() => {
        setIsShowOther(value === -1 || !value);
    }, [value]);
    return (
        <>
            <h4 className="mb-4 font-semibold">Банк кредитор</h4>
            {isLoading ? (
                <Skeleton count={10} />
            ) : (
                <>
                    <ul
                        className={classnames(
                            'flex list-none flex-wrap gap-4',
                            {
                                '[&_label]:border-error ': !!error,
                            },
                        )}>
                        {banks?.map((bank) => (
                            <BankControl
                                key={bank.id}
                                nameControl={name}
                                control={control}
                                rules={rules}
                                onChange={() => {
                                    if (isOtherBank) {
                                        setIsShowOther(false);
                                        setValue(
                                            nameOtherBank,
                                            '' as PathValue<T, Path<T>>,
                                        );
                                    }
                                }}
                                {...bank}
                            />
                        ))}
                        {isOtherBank && (
                            <BankControl
                                id={-1}
                                name="Другой банк"
                                shortName="Другой банк"
                                nameControl={name}
                                control={control}
                                rules={{ ...rules, required: false }}
                                onChange={() => {
                                    if (isOtherBank) {
                                        setIsShowOther(true);
                                        setValue(
                                            name,
                                            null as PathValue<T, Path<T>>,
                                        );
                                        setValue(nameOtherBank, valueOtherBank);
                                    }
                                }}
                            />
                        )}
                    </ul>
                    {isOtherBank && isShowOther && (
                        <Input
                            formKey={nameOtherBank}
                            label="Название банка"
                            register={register}
                            inputProps={{
                                placeholder: 'Введите название банка-кредитора',
                            }}
                            registerOptions={{ required: true }}
                            error={errorOtherBank}
                        />
                    )}
                </>
            )}
            <MessageError error={error} />
        </>
    );
};
