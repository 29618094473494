import { FC, useState } from 'react';
import { NewApplicationModal } from 'features/newApplicationModal';
import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg';
import logo from 'shared/assets/images/logo.svg';
import { Button } from 'shared/ui/Button/ui/Button';
import { Dropdown, ItemMenu } from 'shared/ui/Dropdown';
import { LinkApp } from 'shared/ui/LinkApp';

interface NavbarProps {}

const menu: ItemMenu[] = [
    {
        name: 'Личный кабинет',
        href: '/',
    },
    {
        name: 'Заказать оценку',
        href: 'https://mrqz.me/65e56148c10d08002629d0ea',
    },
];

export const Navbar: FC<NavbarProps> = () => {
    const [open, setOpen] = useState(false);

    return (
        <header className="border-stroke-50 rounded-b-2xl border border-t-0 bg-white p-4 md:px-10 md:py-4 xl:px-20 xl:py-6">
            <div className="flex items-center justify-between gap-9 lg:justify-normal lg:gap-20">
                <a href="/" className="flex shrink-0 items-center gap-2">
                    <img
                        className="mx-auto h-6 w-auto"
                        src={logo}
                        alt="logo"
                        width="138"
                        height="24"
                    />
                </a>
                <div className="flex justify-between gap-4 lg:w-full">
                    <div className="flex items-center lg:gap-10">
                        <Dropdown
                            menu={menu}
                            className="lg:!hidden"
                            icon={
                                <UserIcon className="text-primary-base size-6" />
                            }
                        />
                    </div>
                    <div className="hidden items-center divide-x-2 divide-gray-100 lg:flex">
                        <LinkApp theme="secondary" to="/" className="mr-10">
                            Личный кабинет
                        </LinkApp>
                        <Button className="" onClick={() => setOpen(true)}>
                            Заказать оценку
                        </Button>
                    </div>
                </div>
            </div>
            <NewApplicationModal open={open} setOpen={setOpen} />
        </header>
    );
};
