import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import cls from './Field.module.css';

interface MessageErrorProps {
    error?: FieldError;
    className?: string;
}

export const MessageError: FC<MessageErrorProps> = ({
    error,
    className = '',
}) => {
    return error ? (
        <div className={className}>
            <p className={`${cls.messageInfo}`}>
                <span className="text-error">* </span>
                {error.type === 'required'
                    ? 'Поле обязательно для заполнения'
                    : error.message}
            </p>
        </div>
    ) : null;
};
