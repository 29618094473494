import { FC, PropsWithChildren, createElement } from 'react';
import cls from './Typography.module.css';

type TypographyType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
interface TypographyProps {
    type?: TypographyType;
    typeClass?: TypographyType;
    className?: string;
}

export const Typography: FC<PropsWithChildren<TypographyProps>> = ({
    children,
    type = 'p',
    typeClass,
    className = '',
}) => {
    return createElement(
        type,
        { className: `${typeClass ? cls[typeClass] : cls[type]} ${className}` },
        children,
    );
};
