import { classnames } from '@ocenkatech/common/lib';
import { FC, PropsWithChildren } from 'react';
import { FieldSize } from '../model/types';
import cls from './Field.module.css';

interface SkeletonProps {
    label: string;
    hint?: string;
    size?: FieldSize;
    fieldClassName?: string;
    labelClassName?: string;
}

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
    children,
    label,
    hint,
    size,
    fieldClassName = '',
    labelClassName = '',
}) => {
    return (
        <div
            className={classnames(
                {
                    [cls['wrapper-s']]: size === 's',
                    [cls['wrapper']]: size === 'm',
                    [cls['wrapper-l']]: size === 'l',
                    [cls['wrapper-xl']]: size === 'xl',
                },
                'animate-pulse',
                fieldClassName,
            )}>
            {label && (
                <div
                    className={classnames(
                        cls.label,
                        'mb-2 h-6 w-1/4 rounded-full bg-gray-200',
                        labelClassName,
                    )}
                />
            )}
            {children && (
                <div
                    className={classnames(
                        cls.innerControl,
                        'h-16 w-full rounded-full bg-gray-200',
                    )}
                />
            )}
            {hint && (
                <p
                    className={classnames(
                        cls.messageInfo,
                        'w-1/2 rounded-full bg-gray-200',
                    )}
                />
            )}
        </div>
    );
};
