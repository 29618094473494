'use client';
import { createSlice } from '@reduxjs/toolkit';

var getToken = function (state) { return state.token.token; };

var TOKEN_KEY = 'auth.token';

function getValueInStorage(key) {
    var value = localStorage.getItem(key);
    if (value) {
        return value;
    }
    return sessionStorage.getItem(key);
}

var initialState = {
    token: getValueInStorage(TOKEN_KEY),
};
var slice = createSlice({
    name: 'token',
    initialState: initialState,
    reducers: {
        setToken: function (state, action) {
            state.token = action.payload;
        },
        removeToken: function (state) {
            state.token = null;
        },
    },
});
var tokenReducer = slice.reducer, tokenActions = slice.actions;

export { getToken, tokenActions, tokenReducer };
