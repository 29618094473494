import { classnames } from '@ocenkatech/common/lib';
import { InputHTMLAttributes, ReactNode } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';
import { Field, FieldChildrenProps } from '../../../ui/Field';
import cls from './Input.module.css';

type InputProps<T extends FieldValues> = FieldChildrenProps<T> & {
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    mask?: string;
    icon?: ReactNode;
    leftIcon?: ReactNode;
    rightText?: string | number;
    registerOptions?: RegisterOptions<T>;
};

export const Input = <T extends FieldValues>({
    formKey,
    label,
    error,
    size,
    mask,
    fieldClassName,
    labelClassName,
    isLoading,
    inputProps,
    hint,
    icon,
    leftIcon,
    rightText,
    register,
    registerOptions,
}: InputProps<T>) => {
    const registerWithMask = useHookFormMask(register);
    return (
        <Field
            formKey={formKey}
            label={label}
            error={error}
            hint={hint}
            required={!!registerOptions?.required || inputProps?.required}
            size={size}
            fieldClassName={fieldClassName}
            labelClassName={labelClassName}
            isLoading={isLoading}>
            <div
                className={classnames(cls.inputWrapper, {
                    [cls.inputLeftIcon]: !!leftIcon,
                })}>
                {mask ? (
                    <input
                        style={{
                            paddingRight:
                                rightText &&
                                rightText.toString().length * 7 + 12,
                        }}
                        {...registerWithMask(
                            formKey,
                            mask,
                            registerOptions as any,
                        )}
                        {...inputProps}
                        id={formKey}
                        data-testid={formKey}
                    />
                ) : (
                    <input
                        {...register(formKey, registerOptions)}
                        {...inputProps}
                        id={formKey}
                        data-testid={formKey}
                    />
                )}
                {icon && (
                    <label htmlFor={formKey} className={cls.iconWrapper}>
                        {icon}
                    </label>
                )}
                {leftIcon && (
                    <label htmlFor={formKey} className={`${cls.leftIcon}`}>
                        {leftIcon}
                    </label>
                )}
                {!!rightText && (
                    <div className="pointer-events-none absolute right-4 top-1/2 flex -translate-y-1/2 items-center text-xs">
                        <span className="text-text-secondary sm:text-sm">
                            {rightText}
                        </span>
                    </div>
                )}
            </div>
        </Field>
    );
};
