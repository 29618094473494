import {
    Controller,
    FieldError,
    FieldValues,
    UseControllerProps,
} from 'react-hook-form';
import { Option } from '../../module/types';
import { Select } from '../Select/Select';

interface SelectControlProps<T extends FieldValues>
    extends UseControllerProps<T> {
    options?: Option[];
    label: string;
    isLoading?: boolean;
    fieldClassName?: string;
    emptyValue?: string;
    error?: FieldError;
    onAfterChange?: (event: string) => void;
}

export function SelectControl<T extends FieldValues>(
    props: SelectControlProps<T>,
) {
    const {
        options = [],
        control,
        name,
        label,
        isLoading,
        fieldClassName,
        emptyValue,
        error,
        rules,
        onAfterChange,
    } = props;
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
                <Select
                    {...field}
                    onChange={(event) => {
                        field.onChange(event);
                        onAfterChange?.(event);
                    }}
                    formKey={field.name}
                    label={label}
                    options={options}
                    fieldClassName={fieldClassName}
                    isLoading={isLoading}
                    emptyValue={emptyValue}
                    required={!!rules?.required}
                    error={error}
                />
            )}
        />
    );
}
