import { FC, PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router';
import { Footer } from 'widgets/Footer';
import { Navbar } from 'widgets/Navbar';
import { PageLoader } from 'widgets/PageLoader';
import { MultiFormProvider } from 'entities/orders';

interface MainLayoutProps {}

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = () => {
    return (
        <MultiFormProvider>
            <div className="bg-bg-main text-text-primary flex h-dvh flex-col overflow-y-auto">
                <Navbar />
                <main className="grow px-4 md:px-10 xl:px-20">
                    <Suspense fallback={<PageLoader />}>
                        <Outlet />
                    </Suspense>
                </main>
                <Footer />
            </div>
        </MultiFormProvider>
    );
};
