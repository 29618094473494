import { ChangeEvent } from 'react';
import {
    Controller,
    FieldValues,
    Path,
    UseControllerProps,
} from 'react-hook-form';
import cls from './BankControl.module.css';
import { BankItemProps } from './BankItem';

interface BankControlProps<T extends FieldValues> extends BankItemProps {
    nameControl: Path<T>;
    control?: UseControllerProps<T>['control'];
    id: number;
    rules?: UseControllerProps<T>['rules'];
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const BankControl = <T extends FieldValues>({
    nameControl,
    control,
    id,
    name,
    shortName,
    image,
    rules,
    onChange,
}: BankControlProps<T>) => {
    return (
        <li className={cls.bank}>
            <Controller
                name={nameControl}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <>
                        <input
                            {...field}
                            onChange={
                                onChange
                                    ? (e) => {
                                          field.onChange(e);
                                          onChange(e);
                                      }
                                    : field.onChange
                            }
                            id={nameControl + id}
                            className="hidden"
                            type="radio"
                            value={Number(id)}
                            checked={Number(field.value) === id}
                        />
                        <label
                            className={cls.itemInner}
                            htmlFor={nameControl + id}>
                            {image && (
                                <img
                                    className="size-12 rounded-full object-contain"
                                    src={image}
                                    alt={name}
                                />
                            )}
                            <span className="font-medium">{shortName}</span>
                        </label>
                    </>
                )}
            />
        </li>
    );
};
