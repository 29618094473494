import { FC } from 'react';
import { SkeletonControl } from '../SkeletonControl/SkeletonControl';

interface SkeletonProps {
    count: number;
}

export const Skeleton: FC<SkeletonProps> = ({ count }) => {
    return (
        <ul className="flex animate-pulse list-none flex-wrap gap-4">
            {Array.from({ length: count }).map((_, index) => (
                <SkeletonControl key={index} />
            ))}
        </ul>
    );
};
