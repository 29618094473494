import {
    AUTH_ATTEMPTS_KEY,
    REQUEST_ATTEMPTS_KEY,
    USER_PHONE_NUMBER_KEY,
} from '@ocenkatech/common/const';
import { getValueInStorage, saveInStorage } from '@ocenkatech/common/lib';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthSchema, User } from '../types/authSchema';

const user: User = {
    phoneNumber: getValueInStorage(USER_PHONE_NUMBER_KEY) || '',
    requestAttempts: Number(getValueInStorage(REQUEST_ATTEMPTS_KEY) || ''),
    authAttempts: Number(getValueInStorage(AUTH_ATTEMPTS_KEY) || ''),
};

const initialState: AuthSchema = {
    user: user,
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        decrementRequestAttempts: (state) => {
            state.user && state.user.requestAttempts--;
        },
        decrementAuthAttempts: (state) => {
            if (state.user) {
                state.user.authAttempts--;
                saveInStorage(
                    AUTH_ATTEMPTS_KEY,
                    state.user.authAttempts.toString(),
                );
            }
        },
    },
});

export const { reducer: authReducer, actions: authActions } = slice;
